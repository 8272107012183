import {Component, inject, OnInit} from '@angular/core';
import {Histoire} from "../../models/histoire";
import {HistoireService} from "../../services/histoire.service";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-histoire-list',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './histoire-list.component.html',
  styleUrl: './histoire-list.component.css'
})
export class HistoireListComponent implements OnInit{
  histoires: Histoire[] = [];
  private histoireService = inject(HistoireService);

  ngOnInit(): void {
    this.histoireService.getHistoires().subscribe({
      next: (data) => {
        this.histoires = data;
      },
      error: (error) => {
        console.error('Error retrieving histoires:', error);
      }
  });
  }
}
