<div class="container mt-5">
  @if (histoires && histoires.length) {
  <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">Libellé</th>
      <th scope="col">Date de dernière mise à jour</th>
      <th scope="col">État</th>
    </tr>
    </thead>
    <tbody>
      @for (histoire of histoires; track histoire) {
      <tr>
        <td>{{ histoire.libelle }}</td>
        <td>{{ histoire.dhupdate }}</td>
        <td>{{ histoire.etat }}</td>
      </tr>
      }
    </tbody>
  </table>
  }@else if (!histoires || histoires.length === 0) {
    <div>
      No histoires found.
    </div>
  }
</div>


