<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">
    <img src="images/logo01.png" width="100" height="100" alt="Logo">
  </a>
  <div class="navbar-collapse justify-content-center">
    <span class="navbar-text">Créateurs d'histoires</span>
  </div>
  <button class="btn btn-outline-danger my-2 my-sm-0" type="button">Logout</button>
</nav>

