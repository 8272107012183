import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {Histoire} from "../models/histoire";

@Injectable({
  providedIn: 'root'
})
export class HistoireService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getHistoires(): Observable<Histoire[]> {
    return this.http.get<Histoire[]>(`${this.apiUrl}/histoires`);
  }
}
